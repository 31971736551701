<template>
    <div>
        <h2>Reservations administration page</h2>

        <div class="row mb-3 d-flex justify-content-center">
            <reservations-add :users="users" :vouchers="vouchers" ></reservations-add>
        </div> 
        <div class="row">
            <reservations-table :users="users" :vouchers="vouchers" style="width: 100%"></reservations-table>
        </div>
    </div>
</template>

<script>
import ReservationsTable from '../components/Vouchers/ReservationsTable'
import ReservationsAdd from '../components/Vouchers/ReservationsAdd'

export default {
    name: "AdminReservationsPage",
    components: {
        ReservationsTable,
        ReservationsAdd
    },
    data() {
        return {
            date1: null,
            date2: null,
            users: null,
            vouchers: null
        }
    },
    methods: {    

    },
    computed: {
    },
    created() {
        this.axios.get(this.baseURL+'/admin-api/v0/mem/users?access_token=' + localStorage.getItem('apollo-token')).then(res => {
            this.users = res.data
        });

        this.axios.post(this.baseURL+'/admin-api/v0/bc/vsi_vavcerji?access_token=' + localStorage.getItem('apollo-token')).then(response => {
            this.vouchers = response.data
        })
    }
}
</script>
