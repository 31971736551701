<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{tableTitle}}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table v-if="reservations" dense :headers="headers" :items="reservations" item-key="id" :items-per-page="10" :rows-per-page-items="itemsPerPage">
            <template v-slot:items="props">
                <td class="text-right w-5">
                    <font-awesome-icon style="font-size: 1.4em" :icon="['fas', 'calendar-alt']" class="text-primary" v-bind:class="{'text-danger':props.item.used}"></font-awesome-icon>
                </td>
                <td>
                    {{ props.item.start | formatDateShort }}
                </td>
                <td>
                    {{ props.item.end | formatDateShort }}
                </td>
                <td style="width: 100px">
                    {{ props.item.value }}
                </td>
                <td style="width: 100px">
                    {{ paymentValue(props.item.value, getVoucher(props.item.voucher).vrednost) }}
                </td>
                <td class="d-flex flex-column">
                    <div class="col-12 justify-content-center">
                        {{  getVoucher(props.item.voucher).vrednost  }}
                    </div>
                    <div class="col-12 justify-content-center" style="cursor: pointer" v-if="getVoucher(props.item.voucher)._id">
                        <small class="text-muted text-center" @click="copy(props.item.voucher)"> {{ getVoucher(props.item.voucher)._id }} (click to copy)</small>
                    </div>
                </td>

                <td>
                    {{ props.item.property }}
                </td>

                <td>
                    {{ props.item.payType }}
                </td>

                <td>
                    <span v-if="props.item.user">{{ props.item.user.username }}</span>
                </td>
                    
                <td>
                    <v-btn color="error" style="font-size: 1em" v-on:click="deleteReservation(props.item)">Delete</v-btn>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook, faCalendarAlt);
export default {
    name: "ReservationsTable",
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            itemsPerPage: [10, 20, 50, 100, {"text":"All reservations","value":-1}],
            search: "",
            headers: [
                {value: "icon", text: "", sortable: false},
                {
                    value: "start",
                    text: "Check-in",
                    sortable: true,
                },
                {
                    value: "end",
                    text: "Check-out",
                    sortable: true,
                },
                {value: "totalValue", text: "Total value", sortable: true},
                {value: "paymentValue", text: "Payment value", sortable: true},
                {value: "voucher", text: "Voucher", sortable: true, class: "text-center"},
                {value: "property", text: "Property", sortable: true},
                {value: "payType", text: "Payment type", sortable: true},
                {value: "user", text: "Username", sortable: true},
                {value: "delete", text: "Delete button", sortable: false}
            ],
            tableTitle: "All reservations",
            reservations: null,
        };
    },
    props: [
        'users',
        'vouchers'
    ],
    created() {
        this.interval = setInterval(() => this.fetchReservations(), 10000)
    },
    methods: {
        fetchReservations() {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/vse_rezervacije?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then((response) => {
                
                this.reservations = response.data.map(response => {
                    return {
                        id: response._id,
                        start: response.zacetekRezervacije,
                        end: response.konecRezervacije,
                        voucher: response.vavcer,
                        value: response.vrednostRezervacije,
                        property: (response.nepremicnina == 1) ? "Harmony" : "Simphony",
                        payType: (response.placiloGotovina == true) ? 'EUR' : 'HTL',
                        user: (this.users) ? this.users.find(u => u._id == response.uid) : null,
                    }
                })
            })

        },
        getVoucher(v) {
            if(this.vouchers != null) {
                var ret = this.vouchers.find(v1 => v1._id == v)
                return (ret == null) ? {vrednost: 'No voucher'} : ret
            }
            else
                return 'Couldn\'t find voucher'
        },
        paymentValue(val, voucher) {
            return (Number.isInteger(voucher)) ? Math.max(val - voucher, val / 2) : val
        },
        copy(v) {
            navigator.clipboard.writeText(v)
        },
        deleteReservation(val) {
            console.log(val.id)
            this.axios.post(this.baseURL+'/admin-api/v0/bc/brisi_rezervacijo?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                rezervacija: val.id
            }).then(() => {
                this.fetchReservations()
            })
        }
    },
    computed: {
        reservationsFixed() {
            return this.reservations.map(res => {
                return {
                    ...res,
                    start: this.$options.filters.formatDateShort(res.start),
                    end: this.$options.filters.formatDateShort(res.end),
                }
            })
        }
    },
    watch: {
        users: function() {
            if(this.reservations)
                this.reservations.map(v => {
                    return {
                        ...v,
                        user: this.users.find(u => u._id == v.uid)
                    }
                })
        }
    }
}
</script>

<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px!important;
    height: 16px!important;
    color: white!important;
    border-radius: 50px!important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}

.badge-btn {
    min-width: 110px;
    border-radius: 50px!important;
}

.value-min-width {
    min-width: 150px;
}
</style>