<template>
    <v-card class="col-10 mt-5 d-flex flex-column" v-if="users">
        <v-card-title>
            <h3 class="h5 mb-0"><b>Add reservation</b></h3>
        </v-card-title>
        <v-container class="pt-0 d-flex flex-column">
            <div class="row d-flex flex-row justify-content-between">
                <div class="col-4">
                    <v-select :items="houses" label="Select property" v-model="property" outline></v-select>
                </div>
                <div class="col-4">
                    <v-autocomplete
                item-text='username'
                v-model="user"
                :items="users"
                label="Select user"
                outline
                required
                >
                    <template v-slot:item="user">
                        <v-list-tile-content>
                            <span><b>Username:</b> &nbsp; {{ user.item.username }}</span>
                            <span class="small"><b>Full name:</b> &nbsp; {{ user.item.firstName }} {{ user.item.lastName }}</span>
                        </v-list-tile-content>
                    </template>
                </v-autocomplete>
                </div>
            </div>
            <div class="row justify-content-center">
                <hotel-date-picker v-if="disabledDates" class="w-75" @check-in-changed="checkIn" @check-out-changed="checkOut" :gridStyle="false" :firstDayOfWeek="1" :alwaysVisible="true" :disableCheckoutOnCheckin="true" :disabledDates="disabledDates"></hotel-date-picker>
            </div>
            <div class="row justify-content-center mb-3">
                <div class="col-8 input-group input-group-lg">
                    <div class="input-group-prepend" style="max-width: 25%">
                        <span class="input-group-text">Select voucher</span>
                    </div>
                    <select class="custom-select" v-model="voucher" :disabled="!user">
                        <option v-for="(voucher, index) in userVouchers" :key="index" v-bind:value="voucher">{{ voucher.ime }}</option>
                    </select>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-4">
                    <v-text-field 
                        label="Reservation value" 
                        type="number" 
                        v-model="reservationValue"
                        required outline></v-text-field>
                </div>
                <div class="col-4 d-flex">
                    <v-radio-group style="width: 100%" v-model="radioGroup">
                        <v-radio
                            key="htl"
                            label="Uses HTL from available funds"
                            color="primary"
                            value="htl"
                            active
                        ></v-radio>
                    <v-radio
                        key="eur"
                        label="Pays with cash on arrival"
                        color="primary"
                        value="eur"
                    ></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-4">
                    <v-btn
                    @click="addReservation()"
                    class="btn-primary" style="color: white">
                    Add reservation
                    </v-btn>
                </div>
                <div class="col-4">
                    <v-text-field
                        :value="resValue"
                        label="Reservation value"
                        outline
                        readonly
                    ></v-text-field>
                </div>
            </div>
        </v-container>
    
    </v-card>
</template>
<script>
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import * as moment from "moment";

export default {
    name: "ReservationsAdd",
    components: {
        HotelDatePicker
    },
    data() {
        return {
            user: null,
            value: null,
            date1: null,
            date2: null,
            voucher: null,
            userVouchers: null,
            bookedDates: null,
            fetchingDates: null,
            property: null,
            radioGroup: 'htl',
            reservationValue: null,
            houses: ["Harmony", "Melody"]
        };
    },
    props: [
        'users',
        'vouchers'
    ],
    created() {
    },
    methods: {
        //getBookedDates() {
        //    this.axios.post(this.baseURL+'/admin-api/v0/bc/vse_rezervacije_nepremicnine2?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
        //        nepremicnina: (this.property == 'Harmony') ? 1 : 2
        //    }).then((res) => {
        //        var tmp
        //        tmp = res.data.map(d => {
        //            return new Date(d)
        //        })
		//
        //        tmp = tmp
        //        .map(function (date) { return date.getTime() })
        //        .filter(function (date, i, array) {
        //            return array.indexOf(date) === i;
        //        })
        //        .map(function (time) { return new Date(time); });
		//
		//
        //        this.bookedDates = tmp.sort((a, b) => {
        //            return new Date(a.getTime()) - new Date(b.getTime())
        //        })
		//
        //    })
        //},
        getUserVouchers(usr) {
            if(!this.users || !this.vouchers || !usr)
                this.userVouchers = []
            this.userVouchers = this.vouchers.filter(v => v.uid == this.users.find(u => u.username == usr)._id)
        },
        checkIn(val) {
            console.log(val)
            this.date1 = val;
        },
        checkOut(val) {
            console.log(val)
            this.date2 = val
        },
        addReservation() {
            console.log(this.date1)
            console.log(this.date2)
            console.log(this.voucher)
            console.log(this.resValue)
            console.log(this.reservationValue)
            console.log(this.radioGroup)
            console.log(this.property)
            console.log(this.user)
            if(!this.user || !this.date1 || !this.date2 || !this.property || !this.radioGroup)
                alert("Please fill out all required information")

            this.axios.post(this.baseURL+'/admin-api/v0/bc/admin_ustvari_rezervacijo?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                vavcer: (this.voucher) ? this.voucher._id : null,
                vrednostRezervacije: (this.reservationValue) ? this.reservationValue : this.resValue,
                nepremicnina: (this.property == 'Harmony') ? 1 : 2,
                zacetek: moment(this.date1).format('YYYY-MM-DD'),
                konec: moment(this.date2).format('YYYY-MM-DD'),
                placiloGotovina: (this.radioGroup == "htl") ? false : true ,
                uporabnik: this.user
            }).then(res => {
                if(!res.data.success)
                    alert("An error occured. Please try again")
                else if(res.data.success)
                    alert("Reservation created successfully")
            })
        }
    },
    computed: {
        disabledDates() {
            if(!this.bookedDates)
                return null
            var tmp = this.bookedDates.map(val => {
                return moment(val).format('YYYY-MM-DD')
            })

            return tmp
        },
        resValue() {
            if(this.reservationValue)
                return this.reservationValue
            return (this.date1 && this.date2) ? (moment(this.date2).diff(moment(this.date1), 'days') > 2) ? 
                moment(this.date2).diff(moment(this.date1), 'days') * (100 - 10) : moment(this.date2).diff(moment(this.date1), 'days') * 100 : 0
        },
    },
    watch: {
        property: function() {

            //this.getBookedDates()
        },
        user: function() {
            this.getUserVouchers(this.user)
        }
    }
}
</script>
<style>
     .vhd__datepicker__month-day--halfCheckIn {
        background: linear-gradient(-45deg, rgba(252, 187, 171, 1) 0%, rgba(252, 187, 171, 1) 50%, transparent 26%);
        background-color: rgba(198, 241, 201, 1);
        font-weight: bold
    }

    .vhd__datepicker__month-day--halfCheckIn .checkOut {
        background: none !important;
    }

    .vhd__datepicker__month-day--last-day-selected {
        background: none;
    }

    .vhd__datepicker__month-day--halfCheckIn span {
        background: transparent !important
    }

    .vhd__datepicker__month-day--halfCheckOut {
        background: linear-gradient(135deg, rgba(252, 187, 171, 1) 0%, rgba(252, 187, 171, 1) 50%, transparent 26%);
    }

    .vhd__datepicker__month-day--disabled {
        background-color: rgba(252, 187, 171, 1);
        color: #000;
        
    }

    .vhd__datepicker__month-day--valid {
        background-color: rgba(198, 241, 201, 1)
    }

    .vhd__datepicker__month-day--today .vhd__datepicker__month-day-wrapper {
        border-color: #2fa4e7 !important;
    }

    .vhd__datepicker__month-day--selected {
        background-color: rgba(47, 164, 231, 0.7);
    }

    .vhd__datepicker__month-day--first-day-selected, .vhd__datepicker__month-day--last-day-selected, .vhd__datepicker__month-day--selected:hover, .vhd__datepicker__month-day--valid:hover {
        background-color: rgba(47, 164, 231, 1) !important;
    }

    .vhd__datepicker__month-day--last-day-selected > vhd__datepicker__month-day-wrapper {
        background: transparent
    }

    .vhd__datepicker__month-button {
        border-color: rgba(47, 164, 231, 1);
        color: rgba(47, 164, 231, 1) !important;   
    }

    .vhd__datepicker__month-day--disabled {
        font-weight: bold
    }

</style>